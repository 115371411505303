import React, { useState, createContext, useEffect } from "react";

export const SettingsContext = createContext({});

const SettingsProvider = ({children})=>{
  const [ screenSize, setScreenSize ] = useState("xxl");
  const [ ratio, setRatio ] = useState(1);
  useEffect(()=>{
    const resize=()=>{
      const width=document.body.clientWidth;
      let size="xs";
      if (width>576) size="sm";
      if (width>768) size="md";
      if (width>992) size="lg";
      if (width>1200) size="xl";
      if (width>1600) size="xxl";
      setScreenSize(size);
      setRatio(Math.max(0.5,(Math.min(1.5,(window.innerHeight-300)/Math.min(300,window.innerWidth)))));
      ["xs","sm","md","lg","xl","xxl"].forEach((s) => {
        if (s!==size) document.body.classList.remove(s);
      });
      document.body.classList.add(size);
    }
    resize();
    window.addEventListener('resize',resize);
    window.addEventListener('pageshow',resize);
    return ()=>{
      window.removeEventListener('resize',resize);
      window.removeEventListener('pageshow',resize);
    }
  },[setScreenSize]);
  return (
      <SettingsContext.Provider value={{screenSize,ratio}}>
          {children}
      </SettingsContext.Provider>
  );
}
export default SettingsProvider;
