import React from "react";
import City from 'components/City';
import NotFound from 'components/NotFound';
import {
  useParams,
} from "react-router-dom";

function CityDynamic() {
  const { city } = useParams();
  return city.startsWith('--') ?
    <City city={city}/>
    :
    <NotFound/>
  ;
}

export default CityDynamic;
