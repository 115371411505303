import React from "react";
import "./NotFound.scss";
import Data from 'Data';

function NotFound() {
  return <div className="notfound">
    {Data.notFound.text}
  </div>;
}
export default NotFound;
