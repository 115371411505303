import React, {useContext} from "react";
import "./Home.scss";
import { ModelContext } from "providers/ModelProvider";
import {
  Link,
} from "react-router-dom";
import Data from 'Data';

function Home() {
  const { appState } = useContext(ModelContext);
  const { city } = appState;
  return <div className="home">
    <div className="logo">
      {Data.home.logo}
    </div>
    <div className="text">
      {Data.home.text}
    </div>
    <div className="action">
      <Link to={`/${city}/input/`} className="btn">
        {Data.home.startBtn}
      </Link>
    </div>
  </div>;
}

export default Home;
