import React, {useContext,useEffect,useCallback} from "react";
import "./Universe.scss";
import { SettingsContext } from "providers/SettingsProvider";
import { ModelContext } from "providers/ModelProvider";
import {
  Link,
  useNavigate,
} from "react-router-dom";
import Data from 'Data';
import { Navigation } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

function Universe() {
  const { ratio } = useContext(SettingsContext);
  const { appState,setAppState,valid } = useContext(ModelContext);
  const { playgroundId,city }=appState;
  const playgroundIdx=playgroundId ? Data.universe.playgrounds.findIndex((o)=>o.id===playgroundId) : 0;
  const navigate=useNavigate();
  useEffect(()=>{
    if (valid.length>0) navigate(`/${city}`);
  },[valid,navigate,city]);
  const handlePlaygroundChange=(playgroundId)=>{
    setAppState(state=>{return{
      ...state,
      playgroundId,
    }});
    navigate(`/${city}/start/`);
  }
  return <div className="universe">
    <div className="logo-small">
      {Data.home.logo}
    </div>
    <div className="text">
      {Data.universe.text}
    </div>
    <div className="playgrounds">
      <Swiper
        modules={[Navigation]}
        navigation
        spaceBetween={0}
        initialSlide={playgroundIdx ? playgroundIdx : 0}
        slidesPerView={1}
      >
        {Data.universe.playgrounds.map((playground)=><SwiperSlide key={playground.id}>
          <div className="playground-title">
          {playground.title}
            <div className="trait"></div>
          </div>
          <div className="playground-visuel" style={{paddingBottom:(ratio*95)+'%'}}>
            <div className="playground-visuel-inner" onClick={()=>handlePlaygroundChange(playground.id)}>
              {playground.visuel}
            </div>
          </div>
          <div className="swiper-action">
            <div className="btn" onClick={()=>handlePlaygroundChange(playground.id)}>
              {Data.universe.nextBtn}
            </div>
          </div>
        </SwiperSlide>
        )}
      </Swiper>
    </div>
  </div>;
}

export default Universe;
