import React from "react";
import "./Playing.scss";
import Data from 'Data';

function Playing() {
  return <div className="playing">
    {Data.playing.text}
  </div>;
}
export default Playing;
