import React from 'react';
import waterbay from './assets/Waterbay.jpg';
import rooftop from './assets/Rooftop.jpg';
import downtown from './assets/Downtown.jpg';
import basket from './assets/basket.jpg';
import Conf from 'Conf';
import {ReactComponent as Logo} from './assets/logo.svg';

const Data={
  home:{
    logo:<Logo/>,
    text:'you’re on the basketball court get ready to play !',
    startBtn:'START THE EXPERIENCE',
  },
  input:{
    country:'Country / Region *',
    year:'Year of birth *',
    name:'FIRST NAME',
    email:'EMAIL ADDRESS *',
    nextBtn:'NEXT',
    terms:<div>Agree to <a rel="noreferrer" href={Conf.termsUrl} target="_blank">Terms and Conditions</a></div>,
    errors:{
      age:(age)=>`You must be over ${age}`,
      email:()=>`E-mail is not valid`,
      country:()=>`You must pick a country`,
      terms:()=>`You must agree to the terms and conditions`,
    },
    legalAge:{
      la:21,
      ist:18,
    }
  },
  universe:{
    text:'Please select your basketball court',
    playgrounds:[
      {
        id:1,
        title:'Waterbay',
        visuel:<img alt="playground" src={waterbay}/>
      },
      {
        id:2,
        title:'Rooftop',
        visuel:<img alt="playground" src={rooftop}/>
      },
      {
        id:3,
        title:'Downtown',
        visuel:<img alt="playground" src={downtown}/>
      },
    ],
    nextBtn:'CHOOSE',
  },
  start:{
    drawing:<img alt="playground" src={basket}/>,
    text:<div>Place yourself in the circle, click play and enjoy&nbsp;!</div>,
    playBtn:'PLAY !',
    question:<div>At the end of the game, do you want to take a selfie&nbsp;?</div>,
    questionYes:'Yes',
    questionNo:'No',
  },
  logout:{
    text:<div><p>Your session has expired</p><p>Scan the QR code again</p></div>,
    restart:'RESTART',
  },
  done:{
    text:<div><p>Your game is over</p><p>To play another one, scan the QR code</p></div>,
    restart:'RESTART',
  },
  wait:{
    text:'Please wait',
    rankUnitSingular:'player',
    rankUnitPlural:'players',
    rank:'in the line',
  },
  playing:{
    text:'Your game is in progress',
  },
  notFound:{
    text:'Not found',
  }
}
export default Data;
