import React from "react";
import "./Logout.scss";
import Data from 'Data';

function Logout() {
  return <div className="logout">
    {Data.logout.text}
  </div>;
}
export default Logout;
