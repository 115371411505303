import React from "react";
import {
  Routes,
  Route,
} from "react-router-dom";
import Home from 'components/Home';
import Input from 'components/Input';
import Universe from 'components/Universe';
import Start from 'components/Start';

function Router() {
  return <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/input" element={<Input />} />
    <Route path="/universe" element={<Universe />} />
    <Route path="/start" element={<Start />} />
  </Routes>
}

export default Router;
