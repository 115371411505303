import React from "react";
import "./Done.scss";
import Data from 'Data';

function Done() {
  return <div className="done">
    {Data.done.text}
  </div>;
}
export default Done;
