import React, {useContext,useEffect} from "react";
import "./Start.scss";
import { ModelContext } from "providers/ModelProvider";
import {
  useNavigate,
} from "react-router-dom";
import Data from 'Data';

function Start() {
  const { post,valid,appState,setAppState } = useContext(ModelContext);
  const { city, playgroundId, user } = appState;
  const navigate=useNavigate();
  useEffect(()=>{
    if (valid.length>0) navigate(`/${city}`);
  },[valid,navigate,city]);
  const handleStart=()=>{
    if (user.selfie) {
      console.log('start');
      post('mobile/input',{...user, playgroundId});
    }
  }
  const handleChange=(v)=>{
    setAppState((state)=>{
      return {
        ...state,
        user:{
          ...state.user,
          selfie:v,
        }
      }
    })
  }
  return <div className="start">
    <div className="logo-small">
      {Data.home.logo}
    </div>
    <div className="text">
      {Data.start.text}
    </div>
    <div className="drawing">
      {Data.start.drawing}
    </div>
    <div className="question">
      {Data.start.question}
      <div className="question-btns">
        <div className={"btn"+(user.selfie==='yes' ? ' active' : ' inactive')} onClick={()=>handleChange('yes')}>{Data.start.questionYes}</div>
        <div className={"btn"+(user.selfie==='no' ? ' active' : ' inactive')} onClick={()=>handleChange('no')}>{Data.start.questionNo}</div>
      </div>
    </div>
    <div className="action">
      <div className={"btn"+(user.selfie ? '' : ' disabled')} onClick={handleStart}>
        {Data.start.playBtn}
      </div>
    </div>
  </div>;
}

export default Start;
