import React, {useState,useContext,useEffect} from "react";
import Router from 'components/Router';
import { ModelContext } from "providers/ModelProvider";
import Splashscreen from 'components/Splashscreen';
import Logout from 'components/Logout';
import Wait from 'components/Wait';
import Playing from 'components/Playing';
import Done from 'components/Done';

function City({city}) {
  const { setAppState,appState } = useContext(ModelContext);
  const [ introDone,setIntroDone ] = useState(false);
  useEffect(()=>{
    setAppState(state=>{return{...state,city}});
  },[city,setAppState]);
  const { status }=appState;
  return introDone ? (
    (status==='fired' ? <Logout/> :
      (
        status==='wait' ? <Wait/> :
          (
            status==='done' ? <Done/> :
            (
              status==='play' ? <Playing/> : <Router/>
            )
          )
      )
    )
  ) : <Splashscreen onDone={()=>setIntroDone(true)}/>;
}

export default City;
