import React, {useContext} from "react";
import "./Wait.scss";
import Data from 'Data';
import { ModelContext } from "providers/ModelProvider";

function Wait() {
  const { appState } = useContext(ModelContext);
  const { rank } = appState;
  return <div className="wait">
    <div className="please-wait">{Data.wait.text}</div>
    {rank === 1 && <div className="rank">{rank} {Data.wait.rankUnitSingular} {Data.wait.rank}</div>}
    {rank > 1 && <div className="rank">{rank} {Data.wait.rankUnitPlural} {Data.wait.rank}</div>}
  </div>;
}
export default Wait;
