import React, { useEffect, useState } from "react";
import "./Splashscreen.scss";
import Data from 'Data';

function Splashscreen({onDone}) {
  const [ start,setStart ] = useState(false);
  useEffect(()=>{
    const t=setTimeout(onDone,5000);
    const t2=setTimeout(()=>setStart(true),1000);
    return ()=>{
      clearTimeout(t);
      clearTimeout(t2);
    }
  },[onDone]);
  useEffect(()=>{
    const handleKeyDown=(e)=>{
      if (e.keyCode===83) {
        onDone();
      }
    }
    document.addEventListener('keydown',handleKeyDown);
    return ()=>{
      document.removeEventListener('keydown',handleKeyDown);
    }
  },[onDone]);
  return <>
  {start && <div className="board">
    <div className="logo">{Data.home.logo}</div>
  </div>}
  <div className={"splashscreen-mask"+(start?' started':'')}></div>
  </>;
}

export default Splashscreen;
