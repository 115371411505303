import React from "react";
import './App.scss';
import {
  Routes,
  Route,
} from "react-router-dom";
import City from 'components/City';
import CityDynamic from 'components/CityDynamic';
import NotFound from 'components/NotFound';
import Logout from 'components/Logout';

let isFresh=true;
if (window.performance && window.performance.navigation) {
  const {type} = window.performance.navigation;
  if (type>0) isFresh=false;
}

function App() {

  return isFresh ? <Routes>
    <Route path="/la/*" element={<City city="la"/>} />
    <Route path="/ist/*" element={<City city="ist"/>} />
    <Route path="/:city/*" element={<CityDynamic/>} />
    <Route path="*" element={<NotFound />} />
  </Routes> : <Logout/>;
;
}

export default App;
